import React from "react";

//Style
import "./Nav.scss";

import { connect /*, useStore*/ } from "react-redux";
import lang from "./../../../actionCreator/lang";
import currentCateg from "./../../../actionCreator/currentCateg";
import dictionary from "./../../../lang/lang.js";
import string_to_slug from "./../../../functions/string_to_slug.js";

const Nav = (props) => {
  const { lang, setLang, currentCateg, setCurrentCateg } = props;
  //console.log(lang);
  function changeLang(changeto) {
    setCurrentCateg("");
    if (lang === "en") {
      setLang("fr");
    } else {
      setLang("en");
    }
  }

  function CategHandler(newCateg) {
    /*if (newCateg !== currentCateg) {
      setCurrentCateg(newCateg);
    } else {
      setCurrentCateg("");
    }*/
    var categArray = currentCateg.split(",");
    if (Array.isArray(categArray)) {
      var inarray = false;
      categArray.map((curr) => {
        if (curr.toLowerCase() === newCateg.toLowerCase()) {
          inarray = true;
        }
        return false;
      });
      if (inarray) {
        categArray.splice(categArray.indexOf(newCateg), 1);
      } else {
        categArray.push(newCateg);
      }
      //console.log(categArray);
      setCurrentCateg(categArray.toString());
    } else {
      setCurrentCateg(newCateg);
    }
    return false;
  }

  function createCategClass(currCateg) {
    var categArray = currentCateg.split(",");
    if (currentCateg !== "" && Array.isArray(categArray) && categArray.length) {
      var isActive = false;
      categArray.map((curr) => {
        if (curr === currCateg) {
          isActive = true;
        }
        return null;
      });
      if (isActive) {
        return "";
      } else {
        return "inactive";
      }
    }
    return "";
  }

  return (
    <ul className="Nav">
      <li className="Title">{dictionary[lang].filtre}</li>
      <li>
        <span
          className={
            "Button " +
            string_to_slug(dictionary[lang].categRestauration) +
            " " +
            createCategClass(string_to_slug(dictionary[lang].categRestauration))
          }
          onClick={() => {
            CategHandler(string_to_slug(dictionary[lang].categRestauration));
          }}
        >
          {dictionary[lang].categRestauration}
        </span>
      </li>
      <li>
        <span
          className={
            "Button " +
            string_to_slug(dictionary[lang].categMiseEnValeur) +
            " " +
            createCategClass(string_to_slug(dictionary[lang].categMiseEnValeur))
          }
          onClick={() => {
            CategHandler(string_to_slug(dictionary[lang].categMiseEnValeur));
          }}
        >
          {dictionary[lang].categMiseEnValeur}
        </span>
      </li>
      <li>
        <span
          className={
            "Button " +
            string_to_slug(dictionary[lang].categReutilisation) +
            " " +
            createCategClass(
              string_to_slug(dictionary[lang].categReutilisation)
            )
          }
          onClick={() => {
            CategHandler(string_to_slug(dictionary[lang].categReutilisation));
          }}
        >
          {dictionary[lang].categReutilisation}
        </span>
      </li>
      <li
        className="Lang"
        onClick={() => {
          changeLang(dictionary[lang].otherLang);
        }}
      >
        {dictionary[lang].otherLang}
      </li>
    </ul>
  );
};

//export default Nav;

const mapStateToProps = (state) => {
  return {
    lang: state.lang,
    currentCateg: state.currentCateg,
  };
};
const mapDispatchToProps = (dispatch) => ({
  setLang: (key) => dispatch(lang(key)),
  setCurrentCateg: (key) => dispatch(currentCateg(key)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Nav);
