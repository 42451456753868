import React, { Suspense } from "react";
import { useImage } from "react-image";
import LoaderDots from "../LoaderDots/LoaderDots.js";

function ImageComponent(props) {
  const { src, alt } = props;
  /*const { source } = useImage({
    srcList: src,
    useSuspense: true,
  });*/
  //return <Img className="Image" src={src} alt={alt} loader={LoaderComponent} />;
  return <img className="Image" src={src} alt={alt} loading="lazy" />;
  //return <LoaderComponent />;
}

const LoaderComponent = () => {
  return (
    <div className="Image is-loader">
      <LoaderDots />
    </div>
  );
};

export default function Image(props) {
  const { src, alt } = props;
  return (
    <Suspense fallback={<LoaderComponent />}>
      <ImageComponent src={src} alt={alt} />
    </Suspense>
  );
}
