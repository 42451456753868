var exports = {};
if (window.location.href.indexOf("localhost") > -1 || window.location.href.indexOf("pixelcircusclient") > -1) {
  exports = {
    externalsurl: "https://concours-cprq.pixelcircusclient.com/external/",
  };
}else{
  exports = {
    externalsurl: "https://concours.patrimoine-religieux.qc.ca/external/",
  };
}
export default exports;
