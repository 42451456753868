import React, { useState } from "react";
import { connect } from "react-redux";
import defaults from "../../data/variables.js";
import modalSlideshow from "./../../actionCreator/modalSlideshow";
import Image from "./../../components/Image/Image";

import "./ModalSlideshow.scss";

const ModalSlideshow = (props) => {
  const { slideshow, bootSlideshow } = props;
  const [currImage, setCurrImage] = useState(1);
  //console.log(slideshow);
  function SlideshowNav(to) {
    console.log(to);
    if (!isNaN(to)) {
      setCurrImage(to);
    }
    if (to === "next") {
      to = currImage + 1;
      if (to > Object.keys(slideshow.images).length) {
        to = 1;
      }
      setCurrImage(to);
    }
    if (to === "prev") {
      to = currImage - 1;
      if (to < 1) {
        to = Object.keys(slideshow.images).length;
      }
      setCurrImage(to);
    }
  }
  //console.log(slideshow);
  if (!slideshow || slideshow.slideshowactive === 0) {
    if (currImage !== 1) {
      setCurrImage(1);
    }
    return <div></div>;
  } else {
    return (
      <div className="ModalSlideshow">
        <div
          className="modalBG"
          onClick={() => {
            bootSlideshow({
              slideshowactive: 0,
              images: [],
            });
          }}
        >
          <div
            className="Close"
            onClick={() => {
              bootSlideshow({
                slideshowactive: 0,
                images: [],
              });
            }}
          ></div>
        </div>
        <div className="SlideshowContainer">
          <div className="Flex">
            <div
              className="Nav"
              onClick={() => {
                SlideshowNav("prev");
              }}
            >
              <span></span>
            </div>
            <div className="Photo">
              <Image
                src={
                  defaults.externalsurl +
                  "/images/" +
                  slideshow.images["image" + currImage][0]
                }
                alt={slideshow.images["image" + currImage][1]}
              />
              <div className="Desc">
                {currImage}/{Object.keys(slideshow.images).length} -{" "}
                {slideshow.images["image" + currImage][1]}
              </div>
            </div>
            <div
              className="Nav is-next"
              onClick={() => {
                SlideshowNav("next");
              }}
            >
              <span></span>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    slideshow: state.modalSlideshow,
  };
};
const mapDispatchToProps = (dispatch) => ({
  bootSlideshow: (key) => dispatch(modalSlideshow(key)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalSlideshow);
