import React from "react";

import "./OverPin.scss";

const OverPin = (props) => {
  return (
    <div className="OverPin">
      <h4>{props.title}</h4>
    </div>
  );
};

export default OverPin;
