import React from "react";

//import candidatures_fr from "./../../../src/data/candidatures_fr.json";
//import candidatures_en from "./../../../src/data/candidatures_en.json";
//import { connect /*, useStore*/ } from "react-redux";
//import lang from "./../../actionCreator/lang";
import string_to_slug from "./../../functions/string_to_slug.js";

import "./CardContent.scss";

const CardContent = (props) => {
  const {
    //lang,
    CardData,
    //infos: { id },
  } = props;
  //console.log(props.infos);

  var separator = "";
  if (CardData.adresse && CardData.ville) {
    separator = ", <br />";
  }
  return (
    <div className="CardContent">
      <div className="CardContent__PreTitle">
        <p
          className="CardContent__address"
          dangerouslySetInnerHTML={{
            __html: CardData.adresse + separator + CardData.ville,
          }}
        ></p>
        <p className="CardContent__categ">
          <span
            className={"categ " + string_to_slug(CardData.categorie)}
          ></span>
          {CardData.categorie}
        </p>
      </div>
      <h2 className="CardContent__title">{CardData.titre}</h2>
      <p className="CardContent__description">{CardData.description}</p>
    </div>
  );
};

export default CardContent;
