import React, { Component } from "react";

// Components
import Card from "../Card/Card";

//import { connect /*, useStore*/ } from "react-redux";
//import currentCateg from "./../../actionCreator/currentCateg";

// Style
import "./CardList.scss";

class CardList extends Component {
  render() {
    return (
      <div className="CardList">
        {this.props.candidatures.map((candidat, index) => {
          return (
            <Card
              title={candidat.titre}
              adresse={candidat.adresse}
              ville={candidat.ville}
              key={candidat.id}
              id={candidat.id}
              thumb={candidat.images.image1}
              categorie={candidat.categorie}
              index={index}
              selectCard={this.props.selectCard}
              cardMouseOver={this.props.cardMouseOver}
            />
          );
        })}
      </div>
    );
  }
}

export default CardList;
/*const mapStateToProps = (state) => {
  return {
    //lang: state.lang,
    currentCateg: state.currentCateg,
  };
};
const mapDispatchToProps = (dispatch) => ({
  //setLang: (key) => dispatch(lang(key)),
  setCurrentCateg: (key) => dispatch(currentCateg(key)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CardList);*/
