import React from "react";
import "./LoaderDots.scss";

const LoaderDots = () => {
  return (
    <div className="LoaderDots">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default LoaderDots;
