import React, { Component } from "react";
import "./App.css";

// Data
import candidatures_fr from "../src/data/candidatures_fr.json";
import candidatures_en from "../src/data/candidatures_en.json";

import { Provider } from "react-redux";
import store from "./store";

//import currentCateg from "./actionCreator/currentCateg";

// Style
import "./components/CardList/CardList";

// Components
import CardList from "./components/CardList/CardList";
import Map from "./components/Map/Map";
import CardDetails from "./container/CardDetails/CardDetails";
import Header from "./components/Header/Header";
import ModalSlideshow from "./components/ModalSlideshow/ModalSlideshow";
import Modal from "./components/Modal/Modal";

import PopupThanks from "./components/Popup/PopupThanks/PopupThanks";

import string_to_slug from "./functions/string_to_slug.js";

class App extends Component {
  constructor(props) {
    super(props);
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    var candidatures = candidatures_fr;
    //console.log("resetapp");
    //console.log(store.getState()["lang"]);
    if (store.getState()["lang"] === "en") {
      candidatures = candidatures_en;
    }
    //candidatures = this.shuffle(candidatures);
    this.state = {
      isSelected: false,
      selectedCard: null,
      cardActive: {
        isOver: false,
        id: null,
      },
      hasPopupThanks: window.location.href.indexOf("confirmedvote") !== -1,
      popupVoteID: urlParams.get("confirmedvote"),
      candidatures: candidatures,
    };
    //this.setState({ candidatures: candidatures });
    store.subscribe(this.changeLang);
  }

  changeLang = () => {
    //console.log("sub");
    //console.log(store.getState()["lang"]);
    var candidatures = candidatures_fr;
    //console.log(store.getState()["lang"]);
    if (store.getState()["lang"] === "en") {
      candidatures = candidatures_en;
    }
    this.setState({ candidatures: candidatures });
  };

  /*Popup = () => {
    if (this.state.hasPopupThanks) {
      return (
        <div>
          <PopupThanks />
        </div>
      );
    } else {
      return <div></div>;
    }
  };*/

  selectCardHandler = (id) => {
    console.log(id);
    var newCandidature = [];
    this.state.candidatures.map((cand) => {
      if (cand.id === id) {
        newCandidature = cand;
      }
      return null;
    });
    this.setState({
      selectedCard: newCandidature,
      isSelected: true,
    });
    return null;
  };

  closeCardHandler = () => {
    this.setState({
      isSelected: false,
      selectedCard: null,
      cardActive: {
        isOver: false,
        id: null,
      },
    });
    window.setTimeout(() => {
      console.log("trigger resize");
      var resizeEvent = window.document.createEvent("UIEvents");
      resizeEvent.initUIEvent("resize", true, false, window, 0);
      window.dispatchEvent(resizeEvent);
    }, 30);
  };

  cardOverHandler = (id) => {
    this.setState({ cardActive: this.state.candidatures[id], isOver: true });
  };
  clearPopup = () => {
    this.setState({ hasPopupThanks: 0 });
  };
  shuffle(a) {
    var j, x, i;
    for (i = a.length - 1; i > 0; i--) {
      j = Math.floor(Math.random() * (i + 1));
      x = a[i];
      a[i] = a[j];
      a[j] = x;
    }
    return a;
  }

  render() {
    //console.log(this.state.selectedCard);
    //console.log('x')
    var candidatures = null;
    if (store.getState()["currentCateg"]) {
      candidatures = [];
      var categ = store.getState()["currentCateg"].split(",");
      //console.log(categ);
      categ.map((currCateg) => {
        this.state.candidatures.map((currCand) => {
          if (
            string_to_slug(currCateg) === string_to_slug(currCand.categorie)
          ) {
            candidatures.push(currCand);
          }
          return null;
        });
        return null;
      });
      //candidatures = this.state.candidatures;
    } else {
      candidatures = this.state.candidatures;
    }
    //console.log(candidatures);
    //candidatures = this.shuffle(candidatures);
    return (
      <Provider store={store}>
        <ModalSlideshow />
        <Modal />
        <div>
          <Header />
          <div className="wrapper">
            <div>
              <CardDetails
                closeCard={this.closeCardHandler}
                isSelected={this.state.isSelected}
                candidatDetails={this.state.selectedCard}
              />
              <CardList
                candidatures={candidatures}
                selectedCard={this.state.selectedCard}
                selectCard={this.selectCardHandler}
                cardMouseOver={this.cardOverHandler}
              />
            </div>
            <Map
              candidatures={candidatures}
              selectedCard={this.state.selectedCard}
              selectCard={this.selectCardHandler}
            />
          </div>
          <PopupThanks
            isactive={this.state.hasPopupThanks}
            clearPopup={this.clearPopup}
            voteProject={this.state.popupVoteID}
          />
        </div>
      </Provider>
    );
  }
}

export default App;
/*const mapStateToProps = (state) => {
  return {
    //lang: state.lang,
    currentCateg: state.currentCateg,
  };
};
const mapDispatchToProps = (dispatch) => ({
  //setLang: (key) => dispatch(lang(key)),
  //setCurrentCateg: (key) => dispatch(currentCateg(key)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);*/

//
