import React from "react";

// Components
import Slideshow from "../../components/Slideshow/Slideshow";
import CardContent from "../../components/CardContent/CardContent";
//import ButtonCta from "../../components/UI/Button/ButtonCta/ButtonCta";
import RegistrationForm from "../../components/RegistrationForm/RegistrationForm";
import ButtonRound from "../../components/UI/Button/ButtonRound/ButtonRound";

import candidatures_fr from "./../../../src/data/candidatures_fr.json";
import candidatures_en from "./../../../src/data/candidatures_en.json";
import { connect /*, useStore*/ } from "react-redux";
import lang from "./../../actionCreator/lang";

// Style
import "./CardDetails.scss";

// Image
import closeButton from "../../assets/images/closeButton.png";

const CardDetails = (props) => {
  //console.log(props.candidatDetails.id);
  var candidatures = candidatures_fr;
  var id = 0;
  if (props.candidatDetails) {
    id = props.candidatDetails.id;
    console.log(id);
  }
  //console.log(lang);
  if (lang === "en") {
    candidatures = candidatures_en;
  }

  var CardData = [];
  candidatures.map((currentValue) => {
    if (currentValue.id === id) {
      console.log(currentValue.id);
      CardData = currentValue;
    }
    return false;
  });
  console.log(CardData);
  if (props.isSelected) {
    return (
      <div className="CardDetails">
        <div className="CardDetails__header">
          <ButtonRound icon={closeButton} close={props.closeCard} />
        </div>
        <Slideshow
          images={props.candidatDetails.images}
          projecttitle={props.candidatDetails.titre}
        />
        <CardContent infos={props.candidatDetails} CardData={CardData} />
        <div className="CardDetails__button">
          <RegistrationForm registerid={props.candidatDetails.id} />
        </div>
      </div>
    );
  }
  return null;
};

//export default CardDetails;
const mapStateToProps = (state) => {
  return {
    lang: state.lang,
  };
};
const mapDispatchToProps = (dispatch) => ({
  setLang: (key) => dispatch(lang(key)),
  //setCurrentCateg: (key) => dispatch(currentCateg(key)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CardDetails);
