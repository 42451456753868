import React from "react";
import { connect } from "react-redux";
import ModalReducer from "./../../actionCreator/modal";
import lang from "./../../actionCreator/lang";
import dictionary from "./../../lang/lang.js";

import ButtonCta from "../../components/UI/Button/ButtonCta/ButtonCta";

import "./Modal.scss";

const Modal = (props) => {
  const { modal,setModal,lang } = props;

  if (!modal || modal === 0) {
    return <div></div>;
  } else {
    const ModalContent = () => {
      if(modal === 'voteover'){
        return (
          <div>
            <span dangerouslySetInnerHTML={{__html: dictionary[lang].voteover}}></span>
            <div onClick={() => {setModal(0)}}><ButtonCta text={dictionary[lang].ok} /></div>
          </div>
          )
      }else{
        return <div>Aucun contenu pour ce modal</div>
      }
    };
    return (
      <div className="Modal">
          <div className="modalBG" onClick={() => {setModal(0)}}></div>
          <div className="modalContent"><ModalContent /></div>
      </div>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    modal: state.modal,
    lang:state.lang
  };
};
const mapDispatchToProps = (dispatch) => ({
  setModal: (key) => dispatch(ModalReducer(key)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
