import React, { Component } from "react";
import Image from "./../../components/Image/Image";
//import image from "../../assets/images/image.png";
import defaults from "../../data/variables.js";
// Style
import "./Card.scss";

import dictionary from "../../lang/lang.js";
import { connect /*, useStore*/ } from "react-redux";
import lang from "./../../actionCreator/lang";
import currentCateg from "./../../actionCreator/currentCateg";
import string_to_slug from "./../../functions/string_to_slug.js";

class Card extends Component {
  handleClick = () => {
    window.scrollTo(0, 0);
    console.log(this.props);
    this.props.selectCard(this.props.id);
    // console.log('HandleClick', this.props.index)
  };

  handleMouseOver = () => {
    this.props.cardMouseOver(this.props.id);
    // console.log('1', this.props.cardMouseOver(this.props.index))
  };
  render() {
    //console.log(this.props.currentCateg);
    //console.log(typeof this.props.currentCateg);

    return (
      <div
        className="Card"
        onClick={this.handleClick}
        onMouseOver={this.handleMouseOver}
      >
        <div className="Thumb">
          <Image
            src={defaults.externalsurl + "/images/" + this.props.thumb[0]}
            alt=""
          />
          <div className="hover">
            <span>{dictionary[this.props.lang].voirProjet}</span>
          </div>
        </div>
        <h3>{this.props.title}</h3>
        <p className="City ville">
          <span
            className={"categdot " + string_to_slug(this.props.categorie)}
          ></span>
          {this.props.ville}
        </p>
      </div>
    );
  }
}

//export default Card;
const mapStateToProps = (state) => {
  return {
    lang: state.lang,
    currentCateg: state.currentCateg,
  };
};
const mapDispatchToProps = (dispatch) => ({
  setLang: (key) => dispatch(lang(key)),
  setCurrentCateg: (key) => dispatch(currentCateg(key)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Card);
