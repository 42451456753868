import React, { useState } from "react";

import ButtonCta from "../../components/UI/Button/ButtonCta/ButtonCta";
import Alert from "../../components/UI/Alert/Alert";

import axios from "axios";

import defaults from "../../data/variables.js";

import dictionary from "../../lang/lang.js";
import { connect /*, useStore*/ } from "react-redux";
import lang from "./../../actionCreator/lang";
import modal from "./../../actionCreator/modal";

import "./RegistrationForm.scss";
const RegistrationForm = (props) => {
  const { registerid, lang, setModal } = props;
  const [formstate, setformstate] = useState(0);
  const [formemail, setformemail] = useState("");
  const [formerror, setformerror] = useState(0);
  const [formisloading, setformisloading] = useState(0);
  //console.log(dictionary[lang]);
  function validateEmail(email) {
    //const re = !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
      String(email).toLowerCase()
    );
  }
  function register(e) {
    e.preventDefault();

    if (validateEmail(formemail)) {
      //console.log("Email failed");
      //setformstate(2);
      setformerror(1);
      return false;
    } else {
      //console.log("Email passed");
      //setformstate(3);
      setformisloading(1);
      var url =
        defaults.externalsurl +
        "/php/vote_create.php?formemail=" +
        formemail +
        "&registerid=" +
        registerid;
      //console.log(url);
      axios
        .get(url)
        .then((response) => {
          console.log(response);
          if (response.data.mailexistserror) {
            setformerror(2);
            setformisloading(0);
            //setformstate(2);
          } else {
            setformstate(3);
          }
          return false;
        })
        .catch((error) => {
          console.log(error);
        });
    }
    return false;
  }
  const ErrorMessage = () => {
    if (formerror > 0) {
      var message = "";
      if (formerror === 1) {
        message = dictionary[lang].emailError;
      } else if (formerror === 2) {
        message = dictionary[lang].emailUsed;
      }
      return (
        <div>
          <Alert type="error" text={message} />
        </div>
      );
    } else {
      return (
        <div>
          <Alert type="info" text={dictionary[lang].emailNotice} />
        </div>
      );
    }
  };
  if (formstate === 0) {
    //call to action
    //console.log("step1");
    return (
      <div
        className="RegistrationForm"
        onClick={() => {
          //setformstate(1);
          setModal('voteover');
        }}
      >
        <ButtonCta text={dictionary[lang].votefor} />
      </div>
    );
  } else if (formstate === 1) {
    //console.log("step2");
    //form
    var addedclass = "";
    if (formisloading) {
      addedclass = "is-loading";
    }
    return (
      <form
        className={"RegistrationForm " + addedclass}
        onSubmit={(e) => {
          register(e);
          return false;
        }}
      >
        <ErrorMessage />
        <input
          type="text"
          value={formemail}
          onChange={(e) => {
            setformemail(e.target.value);
          }}
          placeholder={dictionary[lang].email}
        />
        <input type="hidden" value={registerid} />
        <input
          className="ButtonCta"
          type="submit"
          value={dictionary[lang].submit}
        />
      </form>
    );
  } else if (formstate === 3) {
    return (
      <Alert
        type="success"
        text={dictionary[lang].emailOK.replace("{0}", formemail)}
      ></Alert>
    );
  }
};
//export default RegistrationForm;

const mapStateToProps = (state) => {
  return {
    lang: state.lang,
    modal: state.modal
  };
};
const mapDispatchToProps = (dispatch) => ({
  setLang: (key) => dispatch(lang(key)),
  setModal: (key) => dispatch(modal(key)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationForm);
