import React from "react";

// Components
import OverPin from "../OverPin/OverPin";

import { connect /*, useStore*/ } from "react-redux";
//import lang from "./../../actionCreator/lang";
import currentCateg from "./../../actionCreator/currentCateg";
import string_to_slug from "./../../functions/string_to_slug.js";

import "./Marker.scss";

const Marker = (props) => {
  let classes = "Marker";
  const { categorie /*, currentCateg*/ } = props;
  if (props.selected) {
    classes += " selected";
  }
  /*if (
    currentCateg &&
    typeof currentCateg === "string" &&
    currentCateg.toLowerCase() !== string_to_slug(categorie.toLowerCase())
  ) {
    return null;
  }*/
  // OverPinHandler = () => {

  // }

  return (
    <div
      className={
        "marker " + classes + " " + string_to_slug(categorie)
      } /*onMouseOver={OverPinHandler}*/
    >
      <svg
        id="Calque_1"
        data-name="Calque 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 33.33 40"
      >
        <path
          id="path"
          d="M28.78,4.86a16.71,16.71,0,0,0-23.56,0,16.48,16.48,0,0,0,0,23.42L17,40,28.78,28.28A16.48,16.48,0,0,0,28.78,4.86ZM22.89,22.43a8.38,8.38,0,0,1-11.78,0,8.31,8.31,0,1,1,14.22-5.86A8.23,8.23,0,0,1,22.89,22.43Z"
          transform="translate(-0.33 0)"
        />
      </svg>
      <OverPin title={props.title} />
    </div>
  );
};

//export default Marker;
const mapStateToProps = (state) => {
  return {
    //lang: state.lang,
    currentCateg: state.currentCateg,
  };
};
const mapDispatchToProps = (dispatch) => ({
  //setLang: (key) => dispatch(lang(key)),
  setCurrentCateg: (key) => dispatch(currentCateg(key)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Marker);
