import React from "react";
import { connect } from "react-redux";

import defaults from "../../data/variables.js";

import modalSlideshow from "./../../actionCreator/modalSlideshow";
import dictionary from "../../lang/lang.js";
import lang from "./../../actionCreator/lang";

import Image from "./../../components/Image/Image";

// Style
import "./Slideshow.scss";

const Slideshow = (props) => {
  const { images, projecttitle, bootSlideshow, lang } = props;
  //console.log(lang);
  function startSlideshow() {
    bootSlideshow({
      slideshowactive: 1,
      images: images,
    });
  }
  return (
    <div
      className="Slideshow"
      onClick={() => {
        startSlideshow();
      }}
    >
      <Image
        src={defaults.externalsurl + "/images/" + images.image1[0]}
        alt={projecttitle}
      />
      <div className="hover">
        <span>{dictionary[lang].voirDetails}</span>
      </div>
    </div>
  );
};

//export default Slideshow;

const mapStateToProps = (state) => {
  return {
    slideshow: state.slideshow,
    lang: state.lang,
  };
};
const mapDispatchToProps = (dispatch) => ({
  bootSlideshow: (key) => dispatch(modalSlideshow(key)),
  setLang: (key) => dispatch(lang(key)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Slideshow);
