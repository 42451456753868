import React, { Component } from "react";

// Style
import "./ButtonRound.scss";

class ButtonRound extends Component {

  render() {
    return (
      <div className="ButtonRound" onClick={this.props.close}
      >
        <img
          src={this.props.icon}
          alt={this.props.alt}
          width="18px"
          height="18px"
        />
      </div>
    );
  }
  
};

export default ButtonRound;
