import { combineReducers } from "redux";
import lang from "./lang";
import modalSlideshow from "./modalSlideshow";
import currentCateg from "./currentCateg";
import modal from "./modal";

export default combineReducers({
  lang,
  modalSlideshow,
  currentCateg,
  modal,
});
