import React from "react";

import cprqLogo from "../../assets/images/logo-prix-excellence.jpg";
import "./Logo.scss";

const logo = (props) => (
  <div className="Logo">
    <img src={cprqLogo} alt="Conseil du patrimoine religieux du Québec" />
  </div>
);

export default logo;
