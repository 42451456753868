import React from "react";

import "./Alert.scss";

const Alert = (props) => {
  const { type, text } = props;
  return <div className={"Alert is-" + type}>{text}</div>;
};

export default Alert;
