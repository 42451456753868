import React, { useState } from "react";
//import axios from "axios";
import "./PopupThanks.scss";
import candidatures_fr from "../../../../src/data/candidatures_fr.json";
import candidatures_en from "../../../../src/data/candidatures_en.json";

import { connect /*, useStore*/ } from "react-redux";
import dictionary from "./../../../lang/lang.js";
import lang from "./../../../actionCreator/lang";
//import currentCateg from "./../../actionCreator/currentCateg";

const PopupThanks = (props) => {
  const { isactive, clearPopup, voteProject, lang } = props;
  const [votename, setvotename] = useState("");
  var candidatures = candidatures_fr;
  if (lang === "en") {
    candidatures = candidatures_en;
  }
  if (isactive) {
    candidatures.map((currentValue) => {
      //console.log(voteProject + " " + currentValue.id);
      if (String(currentValue.id) === String(voteProject) && votename === "") {
        setvotename(currentValue.titre);
      }
      return null;
    });
    //console.log(candidatures);
    return (
      <div className="PopupThanks">
        <div
          className="PopupBackground"
          onClick={() => {
            clearPopup();
          }}
        ></div>
        <div className="Popup">
          <h1>{dictionary[lang].merci}</h1>
          <p
            dangerouslySetInnerHTML={{
              __html: dictionary[lang].yourvote.replace("{0}", votename),
            }}
          ></p>
          <p>{dictionary[lang].share}</p>
        </div>
      </div>
    );
  } else return <div></div>;
};

//sexport default PopupThanks;
const mapStateToProps = (state) => {
  return {
    lang: state.lang,
    //currentCateg: state.currentCateg,
  };
};
const mapDispatchToProps = (dispatch) => ({
  setLang: (key) => dispatch(lang(key)),
  //setCurrentCateg: (key) => dispatch(currentCateg(key)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PopupThanks);
