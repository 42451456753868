export default function lang(
  state = {
    slideshowactive: 0,
    images: [],
  },
  action
) {
  switch (action.type) {
    case "MODAL_SLIDESHOW":
      console.log(action.payload);
      return action.payload;
    default:
      return state;
  }
}
