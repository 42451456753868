var array = [];
array["fr"] = {
  votefor: "Voter pour ce lieu",
  submit: "Soumettre",
  email: "Courriel",
  emailNotice:
    "Veuillez inscrire votre adresse courriel pour voter pour ce lieu. Vous devrez confirmer votre vote par courriel.",
  emailOK:
    "Un courriel à été envoyé à l'adresse {0}. Veuillez confirmer votre courriel pour valider votre vote.",
  emailError: "L'adresse courriel est erronée.",
  emailUsed: "L'adresse courriel est déjà utilisée.",
  voirProjet: "Voir le projet",
  voirDetails: "Voir les détails du projet",
  otherLang: "EN",
  categMiseEnValeur: "Mise en valeur",
  categRestauration: "Restauration",
  categReutilisation: "Réutilisation",
  filtre: "Filtre :",
  merci: "Merci !",
  yourvote: "<b>Votre vote pour {0}</b> a été enregistré avez succès !",
  share: "Partagez le concours avec vos amis",
  ok:'OK',
  voteover: 'La période de vote est terminée.'
};
array["en"] = {
  votefor: "Vote for this location",
  submit: "Submit",
  email: "Email",
  emailNotice:
    "Please write in your email adress to vote for this location. You must confirm your vote via your email adress.",
  emailOK:
    "An email has been sent to the adress {0} Please confirm your email to validate your vote.",
  emailError: "The email is wrong",
  emailUsed: "This email is already used.",
  voirProjet: "See the project",
  voirDetails: "See project details",
  otherLang: "FR",
  categMiseEnValeur: "Diffusion",
  categRestauration: "Restoration",
  categReutilisation: "Reuse",
  filtre: "Filter:",
  merci: "Thank you!",
  yourvote: "<b>Your vote for {0}</b> has been successfully recorded",
  share: "Share this contest with your friends!",
  ok:'OK',
  voteover: 'The voting period is over.'
};
export default array;
