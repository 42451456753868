import React, { Component } from "react";
import GoogleMapReact from "google-map-react";

// Components
import Marker from "../Marker/Marker";
//import OverPin from "../OverPin/OverPin";

// Style
import "./Map.scss";

// const AnyReactComponent = ({ text }) => <div>{text}</div>;

class Map extends Component {
  static defaultProps = {
    center: {
      lat: 46.5566,
      lng: -68.027853,
    },
    zoom: 6,
    places: [],
  };

  componentDidMount() {
    var placesArray = [];
    this.props.candidatures.map((candidat) => {
      placesArray.push({
        geometry: {
          location: {
            lat: candidat.lat,
            lng: candidat.long,
          },
        },
      });
      return null;
    });
    //console.log("Places");
    //console.log(placesArray);
    this.setState({ places: placesArray });
  }
  componentDidUpdate(prevProps) {
    /*console.log("Prev");
    console.log(prevProps);
    console.log("Now");
    console.log(this.props);*/
    if (prevProps.candidatures !== this.props.candidatures) {
      this.componentDidMount();
      //this.apiIsLoaded(this.state.map, this.state.maps, this.state.places);
    }
  }

  onChildMouseEnter = (key, childProps) => {
    /*console.log("ChildIn");
    console.log(key);
    console.log(childProps);*/
  };
  onChildMouseLeave = (key, childProps) => {
    /*console.log("ChildOut");
    console.log(key);
    console.log(childProps);*/
  };
  onChildClick = (key, childProps) => {
    //console.log("Click");
    //console.log(key);
    //console.log(childProps);
    //console.log(this.props);
    console.log(key);
    console.log(childProps.index);
    this.props.selectCard(childProps.id);
  };

  getMapBounds = (map, maps, places) => {
    const bounds = new maps.LatLngBounds();
    //console.log(this.state.places);
    this.state.places.forEach((place) => {
      bounds.extend(
        new maps.LatLng(
          place.geometry.location.lat,
          place.geometry.location.lng
        )
      );
    });
    return bounds;
  };

  // Re-center map when resizing the window
  bindResizeListener = (map, maps, bounds) => {
    maps.event.addDomListenerOnce(map, "idle", () => {
      maps.event.addDomListener(window, "resize", () => {
        map.fitBounds(bounds);
      });
    });
  };

  // Fit map to its bounds after the api is loaded
  apiIsLoaded = (map, maps, places) => {
    // Get bounds by our places
    const bounds = this.getMapBounds(map, maps, this.state.places);
    // Fit map to bounds
    map.fitBounds(bounds);
    // Bind the resize listener
    this.bindResizeListener(map, maps, bounds);
    this.setState({ map: map, maps: maps });
  };

  render() {
    let center = {
      lat: 46.5566,
      lng: -68.027853,
    };
    let zoom = 6;

    if (this.props.selectedCard) {
      center = {
        lat: this.props.selectedCard.lat,
        lng: this.props.selectedCard.long,
      };
      zoom = 14;
    }
    // Important! Always set the container height explicitly
    return (
      <div className="Map">
        <GoogleMapReact
          bootstrapURLKeys={{
            key: "AIzaSyBBzh2Hty-C-mhUwY5RCi11hu1TJG-oSxY",
            language: "fr-CA",
          }}
          defaultCenter={center}
          defaultZoom={zoom}
          onChildMouseEnter={this.onChildMouseEnter}
          onChildMouseLeave={this.onChildMouseLeave}
          onChildClick={this.onChildClick}
          center={center}
          zoom={zoom}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) =>
            this.apiIsLoaded(map, maps, this.state.places)
          }
          ref={(ref) => {
            this.map = ref;
          }}
        >
          {this.props.candidatures.map((candidat, index) => {
            //console.log(candidat);
            return (
              <Marker
                lat={candidat.lat}
                lng={candidat.long}
                key={candidat.id}
                id={candidat.id}
                title={candidat.titre}
                categorie={candidat.categorie}
                // hover={this.state} À complété par Éric
                selected={candidat === this.props.selectedCard}
                index={index}
              ></Marker>
            );
          })}
        </GoogleMapReact>
      </div>
    );
  }
}

export default Map;
